body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}

#root {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
}

.flex-grow {
    flex-grow: 1;
}